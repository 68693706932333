import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { Pagination } from "../components/pagination";
import { PostCard } from "../components/PostCard";
import { SEO } from "../components/SEO";

interface PropType {
	metadata: Queries.WpSiteMetadataConnection
	category: Queries.WpCategory
	posts: Queries.WpPostConnection
}

export default (props: PageProps<PropType, { maxPage: number, currentPage: number }>) => {
	console.log(props);
	return (
		<>
			<Nav navGroup={props.data.metadata.nodes[0].page_meta!.navLink!} />
			<section className="container py-5">
				<h1 className="text-center">See Latest Posts in {props.data.category.name}</h1>
				<h2 className="text-center text-muted">{props.data.category.description!}</h2>
				<div className="row mt-3">
					{props.data.posts.nodes.map(post => <PostCard post={post} />)}
				</div>
				<div className="row mt-5">
					<Pagination
						maxPages={props.pageContext.maxPage}
						pageUrlGen={(ndx) => `/blog/${props.data.category.slug}/${ndx}`}
						currentPage={props.pageContext.currentPage}
					/>
				</div>
			</section>
			<Footer navGroup={props.data.metadata.nodes[0].page_meta?.footer?.nav_links2!} />
		</>
	)
};

export const Head = (props: PageProps<PropType, { maxPage: number, currentPage: number }>) => {
	let cononicalURL = `/blog/${props.data.category.slug}`;
	if (props.pageContext.currentPage !== 1) {
		cononicalURL = `/blog/${props.data.category.slug}/${props.pageContext.currentPage}`
	}
	return (
		<SEO
			title={`Misty Blog | ${props.data.category.name!}`}
			description={props.data.category.description!}
			pathname={cononicalURL}
		/>
	)
}

export const query = graphql`
query ($id: Int!, $postIDs: [Int!]) {
	posts: allWpPost(filter: {databaseId: {in: $postIDs}}) {
		...PostCardFragment
	}

	category: wpCategory(databaseId: {eq: $id}) {
		name
    slug
    description
	}

	 metadata: allWpSiteMetadata(sort: {fields: modified}) {
    nodes {
      page_meta {
				footer {
					...LinkGroup
				}
				navLink {
					...LinkGroup
				}
      }
		}
	}
}
`